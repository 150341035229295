@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
.marker {
  background-size: cover;
  border-radius: 50%;
  cursor: pointer;
}

.mapboxgl-popup {
  max-width: 200px;
}

.mapboxgl-canvas {
  width: 100% !important;
}
.mapboxgl-popup-content {
  text-align: center;
  font-family: "Open Sans", sans-serif;
}

/* Add some padding inside the card container */
.container {
  text-align: left;
  max-width: 180px;
  height: 100%;
}

.container h4 {
  padding: 0px;
  margin: 0px;
}
.pop-title {
  text-align: center;
}

.button-popup {
  background-color: #009de0;
  color: white;
  text-decoration: none;
  padding: 6px 60px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

@media only screen and (max-width: 600px) {
  .content {
    display: none;
  }
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "Open Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

